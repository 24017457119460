jQuery(function($) {
	var menuRight = document.getElementById('main-nav'),
	showRight = document.getElementById('main-toggle-desktop'),
	body = document.body;

	showRight.onclick = function() {
		classie.toggle( this, 'active' );
		classie.toggle( menuRight, 'cbp-spmenu-open' );

		if(showRight.style.right === '240px') {
			showRight.style.right = '5px';
		} else {
			showRight.style.right = '240px';
		}		
	};

	smoothScroll.init();

	$(document).on('click', '.contact-submit', function(e) {
		e.preventDefault();

		$('#contact-error').addClass('hidden');
		$('#contact-success').addClass('hidden');

		var name = document.getElementById('cf-name').value;
		var email = document.getElementById('cf-email').value;	
		var phone = document.getElementById('cf-phone').value;
		var company = document.getElementById('cf-company').value;
		var message = document.getElementById('cf-message').value;
		var recaptcha = document.getElementById('g-recaptcha-response').value;
		var token = document.getElementById('token').value;
		var action = $(this).closest('form').attr('action');
		
		$.ajax({
			url: action,
			data: {
				_token: token,
				name: name,
				email: email,
				phone: phone,
				company: company,
				message: message,
				recaptcha: recaptcha
			},
			method: 'POST',
			success: function(res) {
				if(res.status == 'ok') {
					$('#contact-success').removeClass('hidden');

					document.getElementById('cf-name').value;
					var email = document.getElementById('cf-email').value;	
					var phone = document.getElementById('cf-phone').value;
					var company = document.getElementById('cf-company').value;
					var message = document.getElementById('cf-message').value;
					var recaptcha = document.getElementById('g-recaptcha-response').value;
					var token = document.getElementById('token').value;
					var action = $(this).closest('form').attr('action');
				} else {					
					$('#contact-error').removeClass('hidden');
				}

				grecaptcha.reset();
			}
		});
	});
});